.logo-wrap {
  width: 100%;
  max-width: 152px;
  color: var(--PrimaryBrandColour);
}

div .sidebarActive{
  background-color: var(--OffWhite);
  color: var(--PrimaryBrandColour);
}

.sidebar-bottom-box p{
  color: var(--Gray);
}
.sidebar-bottom-box .logo-wrap{
  max-width: 103px;
  height: auto;
  margin-bottom: 3px;
}