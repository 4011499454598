@import url(./style/reset.css);
@import url(./style/fonts.css);

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

:root {
  --PrimaryBrandColour: #0515A1;
  --CTATextColour: #0D0C32;
  --CTAButtonColour: #2DEFB5;
  --ThemeOffWhite1: #F4F4F4;
  --ThemeWhite: #FFFFFF;
  --ThemeBGColor: #FAFBFC;
  --D9D9D9: #D9D9D9;
  --PrimaryBlue: #0515A1;
  --PrimaryDark: #0D0C32;
  --GreenLight: #2DEFB5;
  --OffWhite1: #F4F4F4;
  --White: #FFFFFF;
  --PrimaryRed: #A10505;
  --Gray: #9E9E9E;
  --PurpleLight: #8AA9FF;
  --PurpleExtraLight: #BCD6FF;
  --PurpleExtraLight2: #F2F7FF;
  --OffWhite: #FAFBFC;
  --text56: 3.5rem;
  --text40: 2.5rem;
  --text32: 2rem;
  --text24: 1.5rem;
  --text20: 1.25rem;
  --text18: 1.125rem;
  --text16: 1rem;
  --text15: 0.9375rem;
  --text14: 0.875rem;
  --text12: 0.75rem;
  --text8: 0.5rem;
  --Montserrat: 'Montserrat';
  --headerFont: 'Montserrat';
  --bodyFont: 'Montserrat';
}

* {
  box-sizing: border-box;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

* {
  scrollbar-color: var(--Gray);
  scrollbar-width: thin;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--Gray);
  border-radius: 200px;
}

a {
  color: var(--PrimaryBrandColour);
}

.noscroll {
  scrollbar-width: none;
}

.noscroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* .scrollStyle::-webkit-scrollbar {
}

.scrollStyle::-webkit-scrollbar-thumb {
  @apply rounded-full bg-black border-x-2 border-y-2 border-solid border-transparent bg-clip-content;
}

.scrollStyle::-webkit-scrollbar-track {
  @apply bg-GrayLight rounded-full;
} */

html,
body {
  font-size: var(--text16);
  font-weight: 500;
  font-family: var(--Montserrat);
  line-height: 1.5625;
  color: var(--CTATextColour);
  /* background-color: var(--ThemeBGColor); */
}

.stroke-currentColor {
  stroke: currentColor;
}

.fill-currentColor {
  fill: currentColor;
}

.site-button svg {
  margin-left: 17px;
}

.hidden {
  display: none;
}

.block {
  display: block;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.h-auto {
  height: auto;
}

.custom span {
  font-size: unset;
  display: unset;
  font-weight: unset;
  font-family: var(--Montserrat);
  line-height: unset;
  letter-spacing: unset;
}

.text-PrimaryBlue {
  color: var(--PrimaryBlue);
}

.text-PrimaryDark {
  color: var(--PrimaryDark);
}

.text-PrimaryRed {
  color: var(--PrimaryRed);
}

.text-GreenLight {
  color: var(--GreenLight);
}

.text-Gray {
  color: var(--Gray);
}

.text-PurpleLight {
  color: var(--PurpleLight);
}

.text-PurpleExtraLight {
  color: var(--PurpleExtraLight);
}

.text-PurpleExtraLight2 {
  color: var(--PurpleExtraLight2);
}

.text-OffWhite {
  color: var(--OffWhite);
}

.text-White {
  color: var(--White);
}

.header .header-name {
  line-height: 1.75rem;
}

.header .header-subtitle {
  line-height: 0.875rem;
}

[contenteditable]:focus {
  box-shadow: inset 0 0 0 1px #666;
  cursor: text;
}

[contenteditable] {
  word-break: break-word;
}

[contenteditable]:hover {
  box-shadow: inset 0 0 0 1px #666;
  cursor: text;
  padding: 5px;
}

.editable_img:hover {
  cursor: pointer;
}

a.template_builder_cm {
  line-height: 41px;
  text-decoration: none;
}

.dynamic-element {
  position: relative;
  display: block;
}

.drop_placeholder {
  text-align: center;
  border: 1px dashed gray;
  margin: auto;
  max-width: 600px;
  padding: 20px;
}
.template-wrapper-list * {
  width: 100%;
}

.template-wrapper-list__column {
  margin-bottom: 5px;
}

.template-wrapper-list__column:last-child {
  margin-bottom: 0;
}

.template-wrapper-list__column img {
  display: block;
  border-radius: 5px;
  transition: all 0.3s;
}

.template-wrapper-list__column img:active {
  scale: 1.3;
}

.template-wrapper-list__column img:hover {
  scale: 1.01;
}

.css-1to7aaw-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: var(--PrimaryBrandColour) !important;
}

.select-error>div,
.select-error>div:hover,
.select-error>div:focus {
  border-color: #ff0000;
  box-shadow: none !important;
}

.checkBoxRadio input {
  border-radius: 100%;
  appearance: none;
  position: unset;
  width: 18px;
  height: 18px;
  background-color: white;
  opacity: 1;
  box-shadow: 0 0 0 2px var(--PrimaryBrandColour);
}

.checkBoxRadio input:checked {
  background-color: var(--PrimaryBrandColour);
  border: 3px solid var(--White);
}

.checkBoxRadio input:disabled {
  opacity: 0.5;
}

.checkBoxRadio svg {
  display: none;
}

.TabList .Mui-selected {
  background-color: var(--PrimaryBrandColour) !important;
  color: var(--White);
}

textarea {
  display: block;
}

.select-disable>div:nth-child(3) {
  border-color: #9E9E9E;
}

/* .select-active>div:nth-child(3) {
  border-color: var(--PrimaryBrandColour);
} */

.select-disable>div:nth-child(3)>div:nth-child(1)>div:nth-child(1) {
  color: #9E9E9E;
}

textarea {
  margin-bottom: 0 !important;
  width: 100%;
  border-radius: 10px;
  padding: 12px 12px;
  font-family: var(--headerFont);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
}
textarea:focus-visible{
  outline: unset !important;
}
textarea:focus {
  border-color: var(--PrimaryBlue);
  border-width: 2px;
}

.autofill input:-webkit-autofill {
  /* Add styles for autofilled fields */
  background-color: #f0f0f0;
  color: #333;
}

.list-item span{
  font-family: var(--Montserrat);
  font-weight: 600;
  font-size: var(--text14);
  line-height: 20px;
}
/* .list-item:nth-child(2) span{
  color: #72E5E7;
}
.list-item:nth-child(3) span{
  color: #48B8D4;
} */

.MuiMultiSectionDigitalClock-root .MuiMultiSectionDigitalClock-root:after {
  height: unset !important;
}

/* CHrome*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.online-indicator {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 10px;
  
  background-color: #0fcc45;
  border-radius: 50%;
  
  position: relative;
}

span.blink {
  display: block;
  width: 15px;
  height: 15px;
  
  background-color: #0fcc45;
  opacity: 0.7;
  border-radius: 50%;
  
  animation: blink 1s linear infinite;
}

@keyframes blink {
  100% { transform: scale(2, 2); 
          opacity: 0;
        }
}

.form-label-typography{
  all: "initial";
  margin-bottom: 12px !important;
  display: "block"; 
   font-family: var(--Montserrat);
}